import React, { forwardRef, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import NextPage from '../../components/navigation/next/next-container';
import PreviousPage from '../../components/navigation/previous/previous-container';
import SEO from '../../components/seo/seo';

import useVisibleScrollBar from '../../utils/use-visible-scrollbar';
import './page-5.css';

const PageFive = forwardRef((props, ref) => (
  <>
    <SEO title="Page 5" />
    <main className="page5">
      <div className="page__image-container page5__image-portrait-container">
        <StaticImage
          alt="Jimbugs climbing out of the clothes hamper while Amanda asks him questions"
          aspectRatio={4007 / 3258}
          className="page__image page5__image-portrait page__image-border"
          height={750}
          layout="constrained"
          loading="eager"
          objectFit="contain"
          placeholder="tracedSVG"
          src="../../images/page-5-portrait.png"
        />
      </div>
      <div
        className="page__text-container"
        ref={ref}
      >
        <div className="page__text page5__left">
          <div>
            <p>
              &ldquo;So,&rdquo; began Amanda, speaking softly as she could see just how frightened Jimbugs was,
              &ldquo;why are you in the dryer with your head stuck in my sock?&rdquo;
            </p>
            <p>
              &ldquo;It&apos;s my job. I have to collect socks and take them to the workshop. But I have to go now.
              If I&apos;m late I&apos;ll be in big trouble. I got stuck in the sock because it&apos;s dark in there and
              sometimes I can&apos;t see where I&apos;m going.&rdquo;
            </p>
          </div>
          <div className="page__image-container page5__image-landscape1-container">
            <StaticImage
              alt="Jimbugs climbing out of the clothes hamper"
              aspectRatio={3881 / 2943}
              className="page__image page__image-border"
              height={300}
              layout="constrained"
              loading="eager"
              objectFit="contain"
              placeholder="tracedSVG"
              src="../../images/page-5-landscape1.png"
            />
          </div>
        </div>
      </div>
      <div className="page__image-container page5__image-landscape2-container">
        <StaticImage
          alt="Amanda asks Jimbugs some questions"
          aspectRatio={3745 / 5405}
          className="page__image page5__image-landscape2 page__image-border"
          height={750}
          layout="constrained"
          loading="eager"
          placeholder="tracedSVG"
          src="../../images/page-5-landscape2.png"
        />
      </div>
      <PreviousPage to="/page-4" />
      <NextPage to="/page-6" />
    </main>
  </>
));

const PageFiveContainer = () => {
  const textRef = useRef();
  useVisibleScrollBar(textRef, 'page__text-container-scroll-indicator');

  return <PageFive ref={textRef} />;
};

export default PageFiveContainer;
